@import url('https://fonts.googleapis.com/css2?family=Dela+Gothic+One&family=Spline+Sans+Mono:wght@300;400;600&display=swap');

@import "tailwindcss/base";

@import "tailwindcss/components";

@import "tailwindcss/utilities";


@layer base {
  html {
    font-family: 'Dela Gothic One', sans-serif;
    font-family: 'Spline Sans Mono', monospace;
  }
}


